<template>
  <div>
    <form class="form">
      <h2>{{$root.langText.info}}</h2>

      <div v-if="!$route.params.id">

        <div class="form__input">
          <input type="text" v-model="fields.client_name" />
          <span>{{$root.langText.input.pib}}</span>
        </div>

        <div class="form__input">
          <the-mask type="text" v-model="fields.client_phone" :placeholder="['+__(___)-___-__-__']" :mask="['+##(###)-###-##-##']"></the-mask>
          <span>{{$root.langText.input.phone}}</span>
        </div>

      </div>

      <div class="form__input">
        <select v-model="fields.status_id">
          <option v-for="(type, index) in status" :key="index" :value="type.id">
            {{ type.name }}
          </option>
        </select>
        <span>{{$root.langText.input.status}}</span>
        <div class="icon icon-arrow-down"></div>
      </div>

      <div v-if="$route.params.id">
        <Autocomplete
          :data="{
            name: $root.langText.input.managerResp,
            mod: 'team',
            key: 'manager',
            input: fields.manager,
            inputId: fields.manager_id,
          }"
          @result="autocompleteResult"
        />
      </div>


      <div v-if="$route.params.id">
        <Autocomplete
          :data="{
            name: $root.langText.input.client,
            mod: 'clients',
            key: 'client',
            input: fields.client,
            inputId: fields.client_id,
          }"
          @result="autocompleteResult"
        />
      </div>

      <Autocomplete
        :data="{
          name: $root.langText.input.source,
          mod: 'clientsSource',
          key: 'clientsSource',
          input: fields.clientsSource,
          inputId: fields.clientsSource_id,
        }"
        @result="autocompleteResult"
        v-if="ifStatusText(fields.clientsSource)"
      />

      <div class="form__input">
        <p class="form__input__textarea-p">{{ fields.comment }}</p>
        <textarea class="form__input__textarea" v-model="fields.comment" />
        <span>{{$root.langText.input.note}}</span>
      </div>

      <div v-if="$route.params.id">
        <div class="form__input form__input--not-focus">
          <input type="text" v-model="fields.form_name" readonly />
          <span>{{$root.langText.input.typeFormSite}}</span>
        </div>

        <h2
          v-if="fields.utmSource_name || fields.utmMedium || fields.utmCampaign || fields.utmTerm || fields.utmContent"
        >{{$root.langText.input.tagsUTM}}</h2>

        <div class="form__row-50 form__row">
          <div class="form__input form__input--not-focus" v-if="fields.utmSource_name"
            :style="fields.utmMedium ? '' : 'margin-right:0'"
          >
            <input type="text" v-model="fields.utmSource_name" readonly />
            <span>Source</span>
          </div>

          <div class="form__input form__input--not-focus" v-if="fields.utmMedium"
            :style="fields.utmSource_name ? '' : 'margin-right:0'"
          >
            <input type="text" v-model="fields.utmMedium" readonly />
            <span>Medium</span>
          </div>
        </div>

        <div class="form__input form__input--not-focus" v-if="fields.utmCampaign">
          <input type="text" v-model="fields.utmCampaign" readonly />
          <span>Campaign</span>
        </div>

        <div class="form__input form__input--not-focus" v-if="fields.utmTerm">
          <input type="text" v-model="fields.utmTerm" readonly />
          <span>Term</span>
        </div>

        <div class="form__input" v-if="fields.utmContent">
          <p class="form__input__textarea-p">{{ fields.utmContent }}</p>
          <textarea class="form__input__textarea" v-model="fields.utmContent" />
          <span>{{$root.langText.input.request}}</span>
        </div>
      </div>

      <a
        class="btn-save btn btn-primary"
        v-if="$root.edit.can_edit_lead"
        href="#"
        @click.prevent="sendForm"
        >{{$root.langText.save}}</a
      >
      <Message
        v-if="message.popup"
        :message="message"
        @close="message.popup = false"
      />
    </form>
  </div>
</template>




<script>
import Autocomplete from "@/components/app/Autocomplete";
import Message from "@/components/app/Message";
import { TheMask } from "vue-the-mask";

export default {
  props: ["id"],
  data: () => ({
    fields: {
      //add
      client_name: '',
      client_phone: '',

      //update
      status_id: "",
      manager: "",
      manager_id: "",
      client: "",
      client_id: "",
      comment: "",
      clientsSource: "",
      clientsSource_id: "",
      form_name: "",

      utmSource_name: "",
      utmMedium: "",
      utmCampaign: "",
      utmTerm: "",
      utmContent: "",
    },

    status: [],

    leads: null,
    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },
  }),

  async created() {
    if (this.$root.data.leads) {
      this.leads = this.$root.data.leads;
    } else {
      this.leads = this.id;
    }

    this.fields.status_id = this.leads.status_id;
    this.fields.manager = this.leads.manager_name;
    this.fields.manager_id = this.leads.manager_id;
    this.fields.client = this.leads.client_name;
    this.fields.client_id = this.leads.client_id;
    this.fields.comment = this.leads.comment;
    this.fields.clientsSource = this.leads.source_group_name;
    this.fields.clientsSource_id = this.leads.sourceGroup;
    this.fields.form_name = this.leads.form_name;

    this.fields.utmSource_name = this.leads.utmSource_name;
    this.fields.utmMedium = this.leads.utmMedium;
    this.fields.utmCampaign = this.leads.utmCampaign;
    this.fields.utmTerm = this.leads.utmTerm;
    this.fields.utmContent = this.leads.utmContent;

    if (this.$root.status.lead) {
      this.status = this.$root.status.lead;
    } else {
      const res2 = await this.$store.dispatch("getLeadsStatus");
      this.status = res2.data.list;
      this.$root.status.lead = res2.data.list;
    }
  },

  methods: {
    //hidden inputs
    ifStatusText(input) {
      if (input !== null || this.$root.edit.can_edit_lead) return true;
    },

    //autocomplete
    autocompleteResult(data) {
      this.fields[data.input] = data.name;
      this.fields[data.input + "_id"] = data.id;
      if (data.img) {
        this.fields.cityImg = data.img;
      }
    },

    //send data
    async sendForm() {
      var data = {
        status_id: this.fields.status_id,
        manager_id: this.fields.manager_id,
        client_id: this.fields.client_id,
        comment: this.fields.comment,
        source_group: this.fields.clientsSource_id,
      };
      if(this.$route.params.id) {
        data.id = this.leads.id;
        var update = await this.$store.dispatch("updateLeads", data);
        this.$emit("updateData");

        //messedge
        if (update.data.status == "ok") {
          this.message.status = update.data.status;
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.save;
        } else {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.error;
        }
      } else {
        var phone = [this.fields.client_phone]
        var data2 = {
          client_name: this.fields.client_name,
          client_phone: phone,
          status: this.fields.status_id,
          source_group: this.fields.clientsSource_id,
          note: this.fields.comment
        }

        var create = await this.$store.dispatch("addLeads", data2);
        if (create.data.status == "error") {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.fillIn;
        } else {
          this.$router.push("/leads");
        }
      }
    },
  },

  beforeDestroy() {
    var data = {
      status_id: this.fields.status_id,
      manager_name: this.fields.manager,
      manager_id: this.fields.manager_id,
      client_name: this.fields.client,
      client_id: this.fields.client_id,
      comment: this.fields.comment,
      source_group_name: this.fields.clientsSource,
      sourceGroup: this.fields.clientsSource_id,
      form_name: this.fields.form_name,
      utmSource_name: this.fields.utmSource_name,
      utmMedium: this.fields.utmMedium,
      utmCampaign: this.fields.utmCampaign,
      utmTerm: this.fields.utmTerm,
      utmContent: this.fields.utmContent,
    };
    if (this.id) {
      data.id = this.id.id;
    }
    this.$root.data.leads = data;
  },
  components: {
    Autocomplete,
    Message,
    TheMask
  },
};
</script>


