<template>
  <div class="container" >
    <div class="tabs"
      :class="$root.edit.can_edit_lead && canEdit ? '' : 'hide'"
      v-if="leadsId"
    >
      <ul class="tabs__caption" v-if="$route.params.id">
        <li @click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">{{$root.langText.general}}</li>
        <li @click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">{{$root.langText.input.client}}</li>
        <li @click="activetab=3" v-bind:class="[ activetab === 3 ? 'active' : '' ]">{{$root.langText.input.history}}</li>
      </ul>
      
      <!-- tab 1 -->
      <div class="tabs__content" v-if="activetab === 1 && leadsId !== null">
        <LeadsInfo :id="leadsId" @updateData="updateData"/>
      </div>
  
      

      <!-- tab 2 -->
      <div class="tabs__content" v-if="activetab === 2">
        <LeadsContacts :id="leadsId" @updateData="updateData"/>
      </div>

      <!-- tab 3 -->
      <div class="tabs__content" v-if="activetab === 3">
        <LeadsHistory :id="leadsId" @updateData="updateData"/>
      </div>
    </div>
  </div>
</template>




<script>
import LeadsInfo from '@/components/leads/LeadsInfo'
import LeadsContacts from '@/components/leads/LeadsContacts'
import LeadsHistory from '@/components/leads/LeadsHistory'


export default {
  data: () => (
    { 
      activetab: 1, 
      leadsId: null,
      canEdit: true,
      //Title
      globalTitle: [{title: 'Новий лід', info: ''}],
    }
  ),



  async mounted(){

    if(this.$route.params.id){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getLeadsId', id)
      this.leadsId = res.data.detail
      if(this.leadsId){
        this.canEdit = this.leadsId.canEdit

        //Title
        this.$root.title[0].info = this.$root.langText.from + ' ' + new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.leadsId.created_at.replace(/-/g, "/")))
        this.$root.title[0].title = this.$root.langText.directory.leadOne + ' №' + this.leadsId.id
      }


      
    } else{
      this.leadsId = []
      this.create = true
      //title
      this.$root.title[0].title = this.$root.langText.new + ' ' + this.$root.langText.directory.leadOne.toLowerCase()
      this.$root.title = this.globalTitle


    }

  },
  methods: {
    async updateData(){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getLeadsId', id)
      this.leadsId = res.data.detail
    }
  },


  destroyed() {
    this.$root.data.leads = ''
  },
  components: {
    LeadsInfo, LeadsContacts, LeadsHistory
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.leadOne)
    }
  },
}
</script>