<template>
  <form class="form">
    <div class="block-slide-toggle">
      <h2>{{$root.langText.contacts.contactInformation}}</h2>
      <ul class="block-list" v-if="contacts !== undefined">
        <li
          v-for="(list, index) in contacts"
          :key="index"
          @click.prevent="openList('update', index)"
          :data-id="list.id"
        >
          <h5>{{list.name}}</h5>
          <span style="margin-top: -5px; margin-bottom: 10px">{{list.position}}</span>
          <p v-if="list.contacts && list.contacts[0]">{{list.contacts[0].val}}</p>
          <p v-if="list.contacts && list.contacts[1]">{{list.contacts[1].val}}</p>
          <a class="icon icon-arrow-right"></a>
        </li>
      </ul>

      <ul class="block-list" v-if="contacts !== undefined">
        <li
          v-for="(list, index) in contactsOne[0]"
          :key="index"
          @click.prevent="openList('updateOne', index)"
          :data-id="list.id"
        >
          <p style="margin-bottom: 0;padding: 6px 0;">{{list.val}}</p>
          <a class="icon icon-arrow-right"></a>
        </li>
      </ul>

      <p v-else class="block-slide-none">{{$root.langText.contacts.contactsNo}}</p>
      <a class="btn btn-transparent" @click.prevent="openList('create')"  v-if="$root.edit.can_edit_lead && canEdit">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
    </div>
    
    <ContactPopup v-if="contact_popup" :hidden="$root.edit.can_edit_lead && canEdit" :mod="'client'" :id="id.client_id" :modOne="true" :data="contact_popupData" @closeContact="closeContact"/>
    <ContactsPerson v-if="ContactsPerson.flag" :hidden="$root.edit.can_edit_lead && canEdit" :info="ContactsPerson" :id="id.client_id" :dataPerson="contacts" @closeContact="closeContact" @close="ContactsPerson.flag = false" @update="update()" /> 
  </form>
</template>

<style lang="scss">
  .decor-line{
    margin: 20px 0;
  }
</style>



<script>
import ContactsPerson from '@/components/contacts/ContactsPerson'
import ContactPopup from '@/components/contacts/ContactPopup'



export default {
  props: ['id'],
  data: () => ({
    canEdit: true,
    contacts: [],
    contactsOne: [],
    contact_popupData: '',
    contact_popup: false,
    ContactsPerson: {flag: false, id: '', index: '', modPerson: 'client', mod: 'create'},
    dataPerson: '',
  }),

  watch:{
    id: function(){
      this.contacts = []
      this.contactsOne = []
      var app = this
      this.id.contacts_persons.forEach(function(item, index){
        if(item.name){
          app.contacts.push(item)
        } else{
          app.contactsOne.push(item.contacts)
        }
      })
    }
  },


  created() {
    if(this.$route.params.id && this.$route.params.id != 'create'){
      if(this.id){
        this.canEdit = this.id.canEdit
        var app = this
        this.id.contacts_persons.forEach(function(item, index){
          if(item.name){
            app.contacts.push(item)
          } else{
            app.contactsOne.push(item.contacts)
          }
        })
      }
    }
  },
  methods: {
    update(){
      this.$emit('updateData')
    },

    closeContact(data){
      this.ContactsPerson.flag = false
      this.contact_popup = false
      this.$emit('updateData')
    },

    openList(mod, index){
      if(mod == 'update'){
        this.ContactsPerson.mod = 'update'
        this.ContactsPerson.index = index
        this.ContactsPerson.id = this.id.client_id
        this.ContactsPerson.flag = !this.ContactsPerson.flag
      } else if(mod == 'updateOne'){
        this.contact_popupData = this.contactsOne[0][index]
        this.contact_popup = !this.contact_popup
      }else{
        this.ContactsPerson.mod = 'create'
        this.ContactsPerson.id = this.id.client_id
        this.ContactsPerson.flag = !this.ContactsPerson.flag
      }
    }
  },
  components: {
    ContactsPerson, ContactPopup
  }
}
</script>